import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import appReduce, { appStoreInterface } from "./app/appReduce";
import groupSearchReduce, {
  groupSearchStoreInterface,
} from "./groupSearch/groupSearchReduce";
import userDataReduce, {
  userDataStoreInterface,
} from "./userData/userDataReduce";
import alertReduce, { alertStoreInterface } from "./alert/alertReduce";
import learnStartReduce, {
  learnStartStoreInterface,
} from "./learnStart/learnStartReduce";
import userPersonalWordReduce, {
  userPersonalWordStoreInterface,
} from "./userPersonalWord/userPersonalWordReduce";
import formErrorsReduce, {
  formErrorsStoreInterface,
} from "./formErrors/formErrorsReduce";
import wordSearchReduce, {
  wordSearchStoreInterface,
} from "./wordSearch/wordSearchReduce";
import actionWordGroupReduce, {
  actionWordGroupStoreInterface,
} from "./actionWordGroup/actionWordGroupReduce";
import stepperReduce, { stepperStoreInterface } from "./stepper/stepperReduce";
import learningWordGroupPartitionsReduce, {
  learningWordGroupPartitionsStoreInterface,
} from "./learningWordGroupPartitions/learningWordGroupPartitionsReduce";
import learningWordGroupEnvironmentsReduce, {
  learningWordGroupEnvironmentsStoreInterface,
} from "./learningWordGroupEnvironments/learningWordGroupEnvironmentsReduce";
import groupListReduce, {
  groupListStoreInterface,
} from "./groupList/groupListReduce";
import groupDataReduce, {
  groupDataStoreInterface,
} from "./groupData/groupDataReduce";
import groupInfoReduce, {
  groupInfoStoreInterface,
} from "./groupInfo/groupInfoReduce";
import groupTestListReduce, {
  groupTestListStoreInterface,
} from "./groupTestList/groupTestListReduce";
import groupAddTestReduce, {
  groupAddTestStoreInterface,
} from "./groupAddTest/groupAddTestReduce";
import learnTestListReduce, {
  learnTestListStoreInterface,
} from "./learnTestList/learnTestListReduce";
import groupAddExamReduce, {
  groupAddExamStoreInterface,
} from "./groupAddExam/groupAddExamReduce";
import groupExamReduce, {
  groupExamStoreInterface,
} from "./groupExam/groupExamReduce";
import groupRemoveExamReduce, {
  groupRemoveExamStoreInterface,
} from "./groupRemoveExam/groupRemoveExamReduce";
import learnTestReduce, {
  learnTestStoreInterface,
} from "./learnTest/learnTestReduce";
import groupRemoveTestReduce, {
  groupRemoveTestStoreInterface,
} from "./groupRemoveTest/groupRemoveTestReduce";
import learnTestProcessReduce, {
  learnTestProcessStoreInterface,
} from "./learnTestProcess/learnTestProcessReduce";
import learnNewWordReduce, {
  learnNewWordStoreInterface,
} from "./learnNewWord/learnNewWordReduce";
import learnNextWordReduce, {
  learnNextWordStoreInterface,
} from "./learnNextWord/learnNextWordReduce";
import groupDataLearnReduce, {
  groupDataLearnStoreInterface,
} from "./groupDataLearn/groupDataLearnReduce";
import learnProcessReduce, {
  learnProcessStoreInterface,
} from "./learnProcess/learnProcessReduce";
import learnExamReduce, {
  learnExamStoreInterface,
} from "./learnExam/learnExamReduce";
import learnExamProcessReduce, {
  learnExamProcessStoreInterface,
} from "./learnExamProcess/learnExamProcessReduce";
import groupAvailableListReduce, {
  groupAvailableListStoreInterface,
} from "./groupAvailableList/groupAvailableListReduce";
import groupDataPayReduce, {
  groupDataPayStoreInterface,
} from "./groupDataPay/groupDataPayReduce";
import groupEnvironmentReduce, {
  groupEnvironmentStoreInterface,
} from "./GroupEnvironment/groupEnvironmentReduce";
import supportMessagesReduce, {
  supportMessagesStoreInterface,
} from "./supportMessages/supportMessagesReduce";
import workoutNextWordReduce, {
  workoutNextWordStoreInterface,
} from "./workoutNextWord/workoutNextWordReduce";
import workoutProcessReduce, {
  workoutProcessStoreInterface,
} from "./workoutProcess/workoutProcessReduce";
import learnDataReduce, {
  learnDataReduceSlice,
  learnDataStoreInterface,
} from "./learnData/learnDataReduce";
import learnNextReduce, {
  learnNextStoreInterface,
} from "./learnNext/learnNextReduce";
import learnCheckReduce, {
  learnCheckStoreInterface,
} from "./learnCheck/learnCheckReduce";
import userSubscribeDataReduce, {
  userSubscribeDataStoreInterface,
} from "./userSubscribeData/userSubscribeDataReduce";
import userConfirmEmailReduce, {
  userConfirmEmailStoreInterface,
} from "./userConfirmEmail/userConfirmEmailReduce";
import groupPersonalListReduce, {
  groupPersonalListStoreInterface,
} from "./groupPersonalList/groupPersonalListReduce";
import groupPersonalCreateReduce, {
  groupPersonalCreateStoreInterface,
} from "./groupPersonalCreate/groupPersonalCreateReduce";
import groupPersonalDeleteReduce, {
  groupPersonalDeleteStoreInterface,
} from "./groupPersonalDelete/groupPersonalDeleteReduce";
import groupPersonalDataReduce, {
  groupPersonalDataStoreInterface,
} from "./groupPersonalData/groupPersonalDataReduce";
import wordCreateReduce, {
  wordCreateStoreInterface,
} from "./wordCreate/wordCrateReduce";
import groupPersonalUpdateReduce, {
  groupPersonalUpdateStoreInterface,
} from "./groupPersonalUpdate/groupPersonalUpdateReduce";
import wordFromTextReduce, {
  wordFromTextStoreInterface,
} from "./wordFromText/wordFromTextReduce";
import learnGroupPersonalDataReduce, {
  learnGroupPersonalDataStoreInterface,
} from "./learnGroupPersonalData/learnGroupPersonalDataReduce";
import learnGroupPersonalCheckReduce, {
  learnGroupPersonalCheckStoreInterface,
} from "./learnGroupPersonalCheck/learnGroupPersonalCheckReduce";
import learnGroupPersonalNextReduce, {
  learnGroupPersonalNextStoreInterface,
} from "./learnGroupPersonalNext/learnGroupPersonalNextReduce";
import userUnsubscribeReduce, {
  userUnsubscribeStoreInterface,
} from "./userUnsubscribe/userUnsubscribeReduce";
import userGeneratePayReduce, {
  userGeneratePayStoreInterface,
} from "./userGeneratePay/userGeneratePayReduce";
import userLearningWordsReduce, {
  userLearningWordsStoreInterface,
} from "./userLearningWords/userLearningWordsReduce";
import supportSendReduce, {
  supportSendStoreInterface,
} from "./supportSend/supportSendReduce";
import userSendEmailConfirmCodeReduce, {
  userSendEmailConfirmCodeStoreInterface,
} from "./userSendEmailConfirmCode/userSendEmailConfirmCodeReduce";
import authResetPasswordReduce, {
  authResetPasswordStoreInterface,
} from "./authResetPassword/authResetPasswordReduce";
import userCompleteEducationReduce, {
  userCompleteEducationStoreInterface,
} from "./userCompleteEducation/userCompleteEducationReduce";
import learnKnowWordReduce, {
  learnKnowWordStoreInterface,
} from "./learnKnowWord/learnKnowWordReduce";
import learnGroupCheckReduce, {
  learnGroupCheckStoreInterface,
} from "./learnGroupCheck/learnGroupCheckReduce";
import learnGroupDataReduce, {
  learnGroupDataStoreInterface,
} from "./learnGroupData/learnGroupDataReduce";
import learnGroupNextReduce, {
  learnGroupNextStoreInterface,
} from "./learnGroupNext/learnGroupNextReduce";
import checkPayReduce, {
  checkPayStoreInterface,
} from "./checkPay/checkPayReduce";
import learnConfirmLearningWordReduce, {
  learnConfirmLearningWordStoreInterface,
} from "./learnConfirmLearningWord/learnConfirmLearningWordReduce";

export interface storeInterface {
  alert: alertStoreInterface;
  app: appStoreInterface;
  formErrors: formErrorsStoreInterface;
  groupSearch: groupSearchStoreInterface;
  userData: userDataStoreInterface;
  groupDataLearn: groupDataLearnStoreInterface;
  groupDataPay: groupDataPayStoreInterface;
  learnNewWord: learnNewWordStoreInterface;
  learnNextWord: learnNextWordStoreInterface;
  learnProcess: learnProcessStoreInterface;
  learnStart: learnStartStoreInterface;
  userPersonalWord: userPersonalWordStoreInterface;
  wordSearch: wordSearchStoreInterface;
  actionWordGroup: actionWordGroupStoreInterface;
  stepper: stepperStoreInterface;
  learningWordGroupPartitions: learningWordGroupPartitionsStoreInterface;
  learningWordGroupEnvironments: learningWordGroupEnvironmentsStoreInterface;
  groupList: groupListStoreInterface;
  groupAvailableList: groupAvailableListStoreInterface;
  groupAddTest: groupAddTestStoreInterface;
  groupData: groupDataStoreInterface;
  groupInfo: groupInfoStoreInterface;
  groupTestList: groupTestListStoreInterface;
  learnTestList: learnTestListStoreInterface;
  groupAddExam: groupAddExamStoreInterface;
  groupExam: groupExamStoreInterface;
  groupRemoveExam: groupRemoveExamStoreInterface;
  learnTest: learnTestStoreInterface;
  groupRemoveTest: groupRemoveTestStoreInterface;
  learnTestProcess: learnTestProcessStoreInterface;
  learnExam: learnExamStoreInterface;
  learnExamProcess: learnExamProcessStoreInterface;
  groupEnvironment: groupEnvironmentStoreInterface;
  supportMessages: supportMessagesStoreInterface;
  workoutNextWord: workoutNextWordStoreInterface;
  workoutProcess: workoutProcessStoreInterface;
  learnData: learnDataStoreInterface;
  learnNext: learnNextStoreInterface;
  learnCheck: learnCheckStoreInterface;
  userSubscribeData: userSubscribeDataStoreInterface;
  userConfirmEmail: userConfirmEmailStoreInterface;
  groupPersonalList: groupPersonalListStoreInterface;
  groupPersonalCreate: groupPersonalCreateStoreInterface;
  groupPersonalDelete: groupPersonalDeleteStoreInterface;
  groupPersonalData: groupPersonalDataStoreInterface;
  wordCreate: wordCreateStoreInterface;
  groupPersonalUpdate: groupPersonalUpdateStoreInterface;
  wordFromText: wordFromTextStoreInterface;
  learnGroupPersonalData: learnGroupPersonalDataStoreInterface;
  learnGroupPersonalCheck: learnGroupPersonalCheckStoreInterface;
  learnGroupPersonalNext: learnGroupPersonalNextStoreInterface;
  userUnsubscribe: userUnsubscribeStoreInterface;
  userGeneratePay: userGeneratePayStoreInterface;
  userLearningWords: userLearningWordsStoreInterface;
  supportSend: supportSendStoreInterface;
  userSendEmailConfirmCode: userSendEmailConfirmCodeStoreInterface;
  authResetPassword: authResetPasswordStoreInterface;
  userCompleteEducation: userCompleteEducationStoreInterface;
  learnKnowWord: learnKnowWordStoreInterface;
  learnGroupCheck: learnGroupCheckStoreInterface;
  learnGroupData: learnGroupDataStoreInterface;
  learnGroupNext: learnGroupNextStoreInterface;
  checkPay: checkPayStoreInterface;
  learnConfirmLearningWord: learnConfirmLearningWordStoreInterface;
}

const reducers = combineReducers({
  alert: alertReduce,
  app: appReduce,
  formErrors: formErrorsReduce,
  groupSearch: groupSearchReduce,
  userData: userDataReduce,
  groupDataLearn: groupDataLearnReduce,
  groupDataPay: groupDataPayReduce,
  learnNewWord: learnNewWordReduce,
  learnNextWord: learnNextWordReduce,
  learnProcess: learnProcessReduce,
  learnStart: learnStartReduce,
  userPersonalWord: userPersonalWordReduce,
  wordSearch: wordSearchReduce,
  actionWordGroup: actionWordGroupReduce,
  stepper: stepperReduce,
  learningWordGroupPartitions: learningWordGroupPartitionsReduce,
  learningWordGroupEnvironments: learningWordGroupEnvironmentsReduce,
  groupList: groupListReduce,
  groupAvailableList: groupAvailableListReduce,
  groupAddTest: groupAddTestReduce,
  groupData: groupDataReduce,
  groupInfo: groupInfoReduce,
  groupTestList: groupTestListReduce,
  learnTestList: learnTestListReduce,
  groupAddExam: groupAddExamReduce,
  groupExam: groupExamReduce,
  groupRemoveExam: groupRemoveExamReduce,
  learnTest: learnTestReduce,
  groupRemoveTest: groupRemoveTestReduce,
  learnTestProcess: learnTestProcessReduce,
  learnExam: learnExamReduce,
  learnExamProcess: learnExamProcessReduce,
  groupEnvironment: groupEnvironmentReduce,
  supportMessages: supportMessagesReduce,
  workoutNextWord: workoutNextWordReduce,
  workoutProcess: workoutProcessReduce,
  learnData: learnDataReduce,
  learnNext: learnNextReduce,
  learnCheck: learnCheckReduce,
  userSubscribeData: userSubscribeDataReduce,
  userConfirmEmail: userConfirmEmailReduce,
  groupPersonalList: groupPersonalListReduce,
  groupPersonalCreate: groupPersonalCreateReduce,
  groupPersonalDelete: groupPersonalDeleteReduce,
  groupPersonalData: groupPersonalDataReduce,
  wordCreate: wordCreateReduce,
  groupPersonalUpdate: groupPersonalUpdateReduce,
  wordFromText: wordFromTextReduce,
  learnGroupPersonalData: learnGroupPersonalDataReduce,
  learnGroupPersonalCheck: learnGroupPersonalCheckReduce,
  learnGroupPersonalNext: learnGroupPersonalNextReduce,
  userUnsubscribe: userUnsubscribeReduce,
  userGeneratePay: userGeneratePayReduce,
  userLearningWords: userLearningWordsReduce,
  supportSend: supportSendReduce,
  userSendEmailConfirmCode: userSendEmailConfirmCodeReduce,
  authResetPassword: authResetPasswordReduce,
  userCompleteEducation: userCompleteEducationReduce,
  learnKnowWord: learnKnowWordReduce,
  learnGroupCheck: learnGroupCheckReduce,
  learnGroupData: learnGroupDataReduce,
  learnGroupNext: learnGroupNextReduce,
  checkPay: checkPayReduce,
  learnConfirmLearningWord: learnConfirmLearningWordReduce,
});

const initPreloadedState = {};

export const initStore = (preloadedState: any) => ({
  reducer: reducers,
  preloadedState,
});

export default configureStore(initStore(initPreloadedState));
