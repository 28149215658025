import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  ButtonGroup,
  Container,
  CssBaseline,
  Divider,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_LEARN_GROUP_PERSONAL } from "../../constants/titlesConstants";
import AppLoader from "../../components/loader/AppLoader";
import { clearLearnGroupPersonalNextDispatch } from "../../redux/learnGroupPersonalNext/learnGroupPersonalNextDispatcher";
import { clearLearnGroupPersonalCheckDispatch } from "../../redux/learnGroupPersonalCheck/learnGroupPersonalCheckDispatcher";
import NewLearningWordLayout from "../../components/layouts/learn/NewLearningWordLayout";
import newLearningWord from "../../models/newLearningWord";
import ChooseValueComponent from "../../components/chooseValue/ChooseValueComponent";
import selectValueWord from "../../models/selectValueWord";
import LearnProgressLayout from "../../components/layouts/learn/LearnProgressLayout";
import { useSelector } from "react-redux";
import learnGroupPersonalDataSelectors from "../../redux/learnGroupPersonalData/learnGroupPersonalDataSelectors";
import learnGroupPersonalCheckSelectors from "../../redux/learnGroupPersonalCheck/learnGroupPersonalCheckSelectors";
import learnGroupPersonalNextSelectors from "../../redux/learnGroupPersonalNext/learnGroupPersonalNextSelectors";
import { learnGroupPersonalNextApi } from "../../api/learnGroupPersonalNext/learnGroupPersonalNextApi";
import learnGroupPersonalCheckData from "../../api/learnGroupPersonalCheck/learnGroupPersonalCheckData";
import { learnGroupPersonalCheckApi } from "../../api/learnGroupPersonalCheck/learnGroupPersonalCheckApi";
import LastNewLearningWordLayout from "../../components/layouts/learn/LastNewLearningWordLayout";
import { Link, useParams } from "react-router-dom";
import {
  ROUTE_PAY,
  routeGroupsPersonalEditNavigate,
} from "../../constants/routesConstants";
import TranscriptionsComponent from "../../components/transcriptions/TranscriptionsComponent";
import AudioMultipleComponent from "../../components/audioComponent/AudioMultipleComponent";
import EnglishVariantChecker from "../../components/layouts/learn/EnglishVariantChecker";
import { learnGroupPersonalDataApi } from "../../api/learnGroupPersonalData/learnGroupPersonalDataApi";
import learnGroupPersonalDataData from "../../api/learnGroupPersonalData/learnGroupPersonalDataData";
import { clearLearnGroupPersonalDataDispatch } from "../../redux/learnGroupPersonalData/learnGroupPersonalDataDispatcher";
import learnGroupPersonalNextRequestData from "../../api/learnGroupPersonalNext/learnGroupPersonalNextRequestData";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";
import appSelectors from "../../redux/app/appSelectors";
import {
  setLastLearnIdDispatch,
  setLastLearnTypeDispatch,
} from "../../redux/app/appDispatcher";

export default function LearnGroupPersonalPage() {
  const learningWordGroupPersonalId = Number(
    useParams().learningWordGroupPersonalId,
  );
  const [newLearningWord, setNewLearningWord] =
    useState<newLearningWord | null>(null);
  const [progress, setProgress] = useState(0);
  const [selectValueWord, setSelectValueWord] =
    useState<selectValueWord | null>(null);

  const learnGroupPersonalDataLoading = useSelector(
    learnGroupPersonalDataSelectors.learnGroupPersonalDataLoading,
  );
  const lastNewLearningWord = useSelector(
    learnGroupPersonalDataSelectors.lastNewLearningWord,
  );
  const countLearningWord = useSelector(
    learnGroupPersonalDataSelectors.countLearningWord,
  );
  const groupTitle = useSelector(learnGroupPersonalDataSelectors.title);
  const countWords = useSelector(learnGroupPersonalDataSelectors.countWords);
  const updateNewWord = useSelector(
    learnGroupPersonalDataSelectors.updateNewWord,
  );
  const learnGroupPersonalDataProgress = useSelector(
    learnGroupPersonalDataSelectors.progress,
  );
  const learningWordSoundTypes = useSelector(
    learnGroupPersonalDataSelectors.learningWordSoundTypes,
  );
  const learningWordTranscriptionTypes = useSelector(
    learnGroupPersonalDataSelectors.learningWordTranscriptionTypes,
  );
  const issetNotPublicWord = useSelector(
    learnGroupPersonalDataSelectors.issetNotPublicWord,
  );

  const chooseValueWord = useSelector(
    learnGroupPersonalNextSelectors.learnGroupPersonalNext,
  );
  const learnGroupPersonalNextLoading = useSelector(
    learnGroupPersonalNextSelectors.learnGroupPersonalNextLoading,
  );

  const learnGroupPersonalCheckNewLearningWord = useSelector(
    learnGroupPersonalCheckSelectors.newLearningWord,
  );
  const learnGroupPersonalCheckLoading = useSelector(
    learnGroupPersonalCheckSelectors.learnGroupPersonalCheckLoading,
  );
  const learnGroupPersonalCheckProgress = useSelector(
    learnGroupPersonalCheckSelectors.progress,
  );
  const success = useSelector(learnGroupPersonalCheckSelectors.success);

  const paidAccess = useSelector(learnGroupPersonalDataSelectors.paidAccess);

  const completeLearningWordChooseTrue = useSelector(
    appSelectors.completeLearningWordChooseTrue,
  );

  useEffect(() => {
    setLastLearnTypeDispatch("personal");
    setLastLearnIdDispatch(learningWordGroupPersonalId);
    loadData();

    return () => {
      clear();
    };
  }, []);

  function clear() {
    clearLearnGroupPersonalDataDispatch();
    clearLearnGroupPersonalNextDispatch();
    clearLearnGroupPersonalCheckDispatch();
  }

  useEffect(() => {
    setNewLearningWord(lastNewLearningWord);
  }, [lastNewLearningWord]);

  useEffect(() => {
    if (learnGroupPersonalCheckNewLearningWord !== null) {
      setNewLearningWord(learnGroupPersonalCheckNewLearningWord);
    }
  }, [learnGroupPersonalCheckNewLearningWord]);

  useEffect(() => {
    setProgress(learnGroupPersonalDataProgress);
  }, [learnGroupPersonalDataProgress]);

  useEffect(() => {
    setProgress(learnGroupPersonalCheckProgress);
  }, [learnGroupPersonalCheckProgress]);

  useEffect(() => {
    if (selectValueWord !== null) {
      setSelectValueWord({
        ...selectValueWord,
        success: success,
      });
    }

    if (success === true) {
      setTimeout(() => {
        loadNextWord();
        setSelectValueWord(null);
      }, 800);
      // setSuccessAlertShow(true);
    }
  }, [success]);

  useEffect(() => {
    if (learnGroupPersonalDataLoading === false && chooseValueWord === null) {
      loadNextWord();
    }
  }, [learnGroupPersonalDataLoading]);

  function loadData() {
    const data = learnGroupPersonalDataData(learningWordGroupPersonalId);
    learnGroupPersonalDataApi(data);
  }

  function loadNextWord() {
    if (!isContainNotPublicWord()) {
      const data = learnGroupPersonalNextRequestData(
        learningWordGroupPersonalId,
      );
      learnGroupPersonalNextApi(data);
    }
  }

  function continueHandle() {
    clear();
    loadData();
  }

  function isStart() {
    return learnGroupPersonalDataLoading === false;
  }

  function isNewWordShow() {
    return (
      isStart() &&
      (updateNewWord === true ||
        learnGroupPersonalCheckNewLearningWord !== null)
    );
  }

  function isLearnLayoutShow() {
    return (
      !isEmptyGroup() &&
      isStart() &&
      isNewWordShow() === false &&
      !isContainNotPublicWord() &&
      !isCompleteLearn()
    );
  }

  function isCompleteLearn() {
    return chooseValueWord === null && learnGroupPersonalNextLoading === false;
  }

  function isContainNotPublicWord() {
    return issetNotPublicWord === true;
  }

  function isEmptyGroup() {
    return countWords === 0;
  }

  function selectValueHandle(select: selectValueWord) {
    setSelectValueWord(select);

    const data = learnGroupPersonalCheckData(
      learningWordGroupPersonalId,
      select.learningWord.learning_word_id,
      select.chooseWordTranslate.learning_word_translate_id,
    );

    learnGroupPersonalCheckApi(data);
  }

  function isShowPaidLayout() {
    return paidAccess === false;
  }

  function ButtonChooseSkeleton() {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          margin: 1,
          minWith: 200,
          p: 2,
          fontWeight: "bold",
          fontSize: 18,
          height: 64,
        }}
      />
    );
  }

  function LoadingLayout() {
    return (
      <>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 61,
          }}
        >
          <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
            <Skeleton variant="text" sx={{ width: 250 }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            fontSize: 20,
            fontStyle: "italic",
            mb: 2,
            height: 32,
          }}
        >
          <Skeleton variant="text" sx={{ width: 150 }} />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "0px",
            color: "#9a9a9a",
          }}
        >
          {"(Выберите правильное значение)"}
        </Box>
        <Box
          display={"flex"}
          justifyContent="center"
          sx={{ marginTop: "15px" }}
        >
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            sx={{
              p: 2,
              width: "100%",
              maxWidth: 400,
            }}
          >
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
          </ButtonGroup>
        </Box>
      </>
    );
  }

  function PaidLayout() {
    return (
      <>
        <Alert severity="warning">
          {"Новые слова не будут появляться."}
          <Box component={"span"} sx={{ pl: 2 }}>
            <Link to={ROUTE_PAY}>{"Оплатитe подписку"}</Link>
          </Box>
        </Alert>
      </>
    );
  }

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container
        maxWidth="md"
        sx={{ minHeight: isContainNotPublicWord() ? "auto" : 550 }}
      >
        <TitleLineComponent title={TITLE_PAGE_LEARN_GROUP_PERSONAL} />
        {isStart() && isEmptyGroup() ? (
          <>
            <Box
              sx={{
                FontSize: 24,
                textAlign: "center",
                mt: 4,
                mb: 4,
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              {"Словарь пустой"}
            </Box>
            <Box sx={{ FontSize: 24, textAlign: "center", mb: 0 }}>
              {"Заполните словарь в разделе "}
              <Link
                to={routeGroupsPersonalEditNavigate(
                  String(learningWordGroupPersonalId),
                )}
              >
                {"редактирования словаря"}
              </Link>
            </Box>
          </>
        ) : (
          ""
        )}
        {isStart() && isContainNotPublicWord() ? (
          <>
            <Box
              sx={{
                FontSize: 24,
                textAlign: "center",
                mt: 4,
                mb: 4,
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              {"Слова на модерации"}
            </Box>
            <Box sx={{ FontSize: 24, textAlign: "center", mb: 1 }}>
              {
                "Словарь содержит слова, которые еще не прошли модерацию. Приступить к изучению не получится."
              }
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Link
                to={routeGroupsPersonalEditNavigate(
                  String(learningWordGroupPersonalId),
                )}
              >
                {"Перейти к редактированию"}
              </Link>
            </Box>
          </>
        ) : (
          ""
        )}
        {isShowPaidLayout() ? <PaidLayout /> : ""}
        {isNewWordShow() && newLearningWord ? (
          <NewLearningWordLayout
            newLearningWord={newLearningWord}
            learningWordTranscriptionTypes={learningWordTranscriptionTypes}
            learningWordSoundTypes={learningWordSoundTypes}
            continueHandle={continueHandle}
          />
        ) : (
          ""
        )}
        {isLearnLayoutShow() ? (
          <>
            <Box sx={{ fontWeight: "bold" }}>
              <Tooltip
                enterTouchDelay={0}
                title={
                  "Изучено слов: " + countLearningWord + " из " + countWords
                }
              >
                <Box component={"span"}>
                  {countLearningWord + "/" + countWords + " " + groupTitle}
                </Box>
              </Tooltip>
            </Box>
            <EnglishVariantChecker />
            <LearnProgressLayout progress={progress} />
            {chooseValueWord && learnGroupPersonalNextLoading === false ? (
              <>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 61,
                  }}
                >
                  <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
                    {chooseValueWord.sounds ? (
                      <AudioMultipleComponent
                        learningWordSounds={chooseValueWord.sounds}
                        learningWordSoundTypes={learningWordSoundTypes}
                      />
                    ) : (
                      ""
                    )}
                    {chooseValueWord.learningWord.word}
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    fontStyle: "italic",
                    mb: 2,
                    height: 32,
                  }}
                >
                  <TranscriptionsComponent
                    transcriptions={chooseValueWord.transcriptions}
                    learningWordTranscriptionTypes={
                      learningWordTranscriptionTypes
                    }
                  />
                </Box>
                <ChooseValueComponent
                  chooseValueWord={chooseValueWord}
                  selectValueHandle={selectValueHandle}
                  selectValueWord={selectValueWord}
                  disable={
                    learnGroupPersonalCheckLoading ||
                    learnGroupPersonalNextLoading
                  }
                />
              </>
            ) : (
              ""
            )}
            {learnGroupPersonalNextLoading === true ? <LoadingLayout /> : ""}
          </>
        ) : (
          ""
        )}
        {isLearnLayoutShow() && newLearningWord ? (
          <Box sx={{ mt: "40px" }}>
            <LastNewLearningWordLayout newLearningWord={newLearningWord} />
          </Box>
        ) : (
          ""
        )}
        {!isStart() ? (
          <>
            <AppLoader />
          </>
        ) : (
          ""
        )}
        {isCompleteLearn() ? (
          <>
            <Box
              sx={{
                FontSize: 24,
                textAlign: "center",
                mt: 4,
                mb: 4,
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              {"Вы выучили все слова"}
            </Box>
            <Box sx={{ FontSize: 24, textAlign: "center", mb: 1 }}>
              {"Это значит, что значение каждого изученного из словаря слова вы правильно выбрали более " +
                completeLearningWordChooseTrue +
                " раз. Вы навсегда запомнили заничения этих слов. Вам больше нет смысла показывать эти слова."}
            </Box>
          </>
        ) : (
          ""
        )}
      </Container>
      <Footer />
    </>
  );
}
