import { storeInterface } from "../store";

const learnConfirmLearningWordLoading = (state: storeInterface) =>
  state.learnConfirmLearningWord.learnConfirmLearningWordLoading;
const learnConfirmLearningWordComplete = (state: storeInterface) =>
  state.learnConfirmLearningWord.learnConfirmLearningWordComplete;

export default {
  learnConfirmLearningWordLoading,
  learnConfirmLearningWordComplete,
};
