import { Box, Button, Divider, Paper } from "@mui/material";
import React from "react";
import TranscriptionsComponent from "../../transcriptions/TranscriptionsComponent";
import TranslatesComponent from "../../translates/TranslatesComponent";
import newLearningWord from "../../../models/newLearningWord";
import learningWordTranscriptionType from "../../../models/learningWordTranscriptionType";
import AudioMultipleComponent from "../../audioComponent/AudioMultipleComponent";
import learningWordSoundType from "../../../models/learningWordSoundType";
import {
  bgColorByWordType,
  colorByWordType,
  imageByWordType,
  titleByWordType,
} from "../../../helpers/typeWordHelper";
import KnowWordLayout from "./KnowWordLayout";

interface propsInterface {
  newLearningWord: newLearningWord;
  learningWordTranscriptionTypes: learningWordTranscriptionType[];
  learningWordSoundTypes: learningWordSoundType[];
  continueHandle: CallableFunction;
}

export default function NewLearningWordLayout(props: propsInterface) {
  return (
    <>
      <Paper elevation={6} sx={{ mb: 4, p: 2 }}>
        <KnowWordLayout
          learningWordId={props.newLearningWord.learningWord.learning_word_id}
          continueHandle={props.continueHandle}
        />
      </Paper>
      <Paper
        elevation={0}
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          backgroundColor: bgColorByWordType(0),
          backgroundImage:
            "url(" +
            imageByWordType(props.newLearningWord.learningWord.type) +
            ")",
          backgroundSize: "auto 200px",
          backgroundPosition: "bottom right",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            mt: 5,
            pt: 5,
          }}
        >
          <Box
            sx={{
              p: 1,
              fontSize: 38,
              color: colorByWordType(props.newLearningWord.learningWord.type),
              textTransform: "uppercase",
            }}
          >
            <AudioMultipleComponent
              learningWordSounds={props.newLearningWord.sounds}
              learningWordSoundTypes={props.learningWordSoundTypes}
            />
            {props.newLearningWord.learningWord.word}
          </Box>
          <Box
            sx={{
              p: 1,
              fontSize: 16,
              color: "#fff",
              backgroundColor: colorByWordType(
                props.newLearningWord.learningWord.type,
              ),
              borderRadius: 1,
            }}
          >
            {titleByWordType(props.newLearningWord.learningWord.type)}
          </Box>
        </Box>
        <Box sx={{ p: 1, mb: 1 }}>
          <Box
            sx={{
              fontSize: 20,
              fontStyle: "italic",
            }}
          >
            <TranscriptionsComponent
              transcriptions={props.newLearningWord.transcriptions}
              learningWordTranscriptionTypes={
                props.learningWordTranscriptionTypes
              }
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Divider></Divider>
          </Box>
          <Box
            sx={{
              textTransform: "uppercase",
              fontSize: 18,
              mt: 1,
              mb: 6,
            }}
          >
            <TranslatesComponent
              translates={props.newLearningWord.translates ?? []}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
}
