import store from "../store";
import {
  clearLearnConfirmLearningWord,
  setLearnConfirmLearningWordLoadingFalse,
  setLearnConfirmLearningWordLoadingTrue,
  setLearnConfirmLearningWordComplete,
} from "./learnConfirmLearningWordReduce";

export function clearLearnConfirmLearningWordDispatch() {
  store.dispatch(clearLearnConfirmLearningWord());
}

export function setLearnConfirmLearningWordLoadingTrueDispatch() {
  store.dispatch(setLearnConfirmLearningWordLoadingTrue());
}

export function setLearnConfirmLearningWordLoadingFalseDispatch() {
  store.dispatch(setLearnConfirmLearningWordLoadingFalse());
}

export function setLearnConfirmLearningWordCompleteDispatch(
  value: boolean | null,
) {
  store.dispatch(setLearnConfirmLearningWordComplete(value));
}
