import { createSlice } from "@reduxjs/toolkit";

export interface learnConfirmLearningWordStoreInterface {
  learnConfirmLearningWordLoading: boolean;
  learnConfirmLearningWordComplete: boolean | null;
}

export const initState: learnConfirmLearningWordStoreInterface = {
  learnConfirmLearningWordLoading: false,
  learnConfirmLearningWordComplete: null,
};

export const learnConfirmLearningWordReduceSlice = createSlice({
  name: "learnConfirmLearningWordReduce",
  initialState: initState,
  reducers: {
    clearLearnConfirmLearningWord: (
      state: learnConfirmLearningWordStoreInterface,
    ) => {
      state.learnConfirmLearningWordLoading =
        initState.learnConfirmLearningWordLoading;
      state.learnConfirmLearningWordComplete =
        initState.learnConfirmLearningWordComplete;
    },
    setLearnConfirmLearningWordLoadingTrue: (
      state: learnConfirmLearningWordStoreInterface,
    ) => {
      state.learnConfirmLearningWordLoading = true;
    },
    setLearnConfirmLearningWordLoadingFalse: (
      state: learnConfirmLearningWordStoreInterface,
    ) => {
      state.learnConfirmLearningWordLoading = false;
    },
    setLearnConfirmLearningWordComplete: (
      state: learnConfirmLearningWordStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.learnConfirmLearningWordComplete = action.payload;
    },
  },
});

export const {
  clearLearnConfirmLearningWord,
  setLearnConfirmLearningWordLoadingTrue,
  setLearnConfirmLearningWordLoadingFalse,
  setLearnConfirmLearningWordComplete,
} = learnConfirmLearningWordReduceSlice.actions;

export default learnConfirmLearningWordReduceSlice.reducer;
