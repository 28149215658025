import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { clearLearnKnowWordDispatch } from "../../../redux/learnKnowWord/learnKnowWordDispatcher";
import learnKnowWordData from "../../../api/learnKnowWord/learnKnowWordData";
import learnKnowWordApi from "../../../api/learnKnowWord/learnKnowWordApi";
import { useSelector } from "react-redux";
import learnKnowWordSelectors from "../../../redux/learnKnowWord/learnKnowWordSelectors";
import { clearLearnConfirmLearningWordDispatch } from "../../../redux/learnConfirmLearningWord/learnConfirmLearningWordDispatcher";
import learnConfirmLearningWordSelectors from "../../../redux/learnConfirmLearningWord/learnConfirmLearningWordSelectors";
import learnConfirmLearningWordApi from "../../../api/learnConfirmLearningWord/learnConfirmLearningWordApi";

interface propsInterface {
  learningWordId: number;
  continueHandle: CallableFunction;
}

export default function KnowWordLayout(props: propsInterface) {
  const [show, setShow] = useState(false);

  const learnKnowWordLoading = useSelector(
    learnKnowWordSelectors.learnKnowWordLoading,
  );
  const learnKnowWordComplete = useSelector(
    learnKnowWordSelectors.learnKnowWordComplete,
  );

  const learnConfirmLearningWordLoading = useSelector(
    learnConfirmLearningWordSelectors.learnConfirmLearningWordLoading,
  );
  const learnConfirmLearningWordComplete = useSelector(
    learnConfirmLearningWordSelectors.learnConfirmLearningWordComplete,
  );

  useEffect(() => {
    return () => {
      clearLearnKnowWordDispatch();
      clearLearnConfirmLearningWordDispatch();
    };
  }, []);

  useEffect(() => {
    if (learnKnowWordComplete === true) {
      props.continueHandle();
    }
  }, [learnKnowWordComplete]);

  useEffect(() => {
    if (learnConfirmLearningWordComplete === true) {
      props.continueHandle();
    }
  }, [learnConfirmLearningWordComplete]);

  function handleClose() {
    setShow(false);
  }

  function handleOpen() {
    setShow(true);
  }

  function handleSkip() {
    const data = learnKnowWordData(props.learningWordId);
    learnKnowWordApi(data);
  }

  function handleContinue() {
    learnConfirmLearningWordApi();
  }

  return (
    <>
      <Dialog onClose={handleClose} open={show}>
        <Box sx={{ textAlign: "right" }}>
          <Button onClick={handleClose}>{"Закрыть"}</Button>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ textAlign: "left", fontSize: 20, fontWeight: "bold" }}>
            {"Вы уверены, что знаете это слово?"}
          </Box>
          <Box sx={{ fontSize: 14, color: "rgba(0,0,0,0.6)" }}>
            {"Во время обучения это слово не будет появляться."}
          </Box>
          <Box sx={{ pt: 3, textAlign: "right" }}>
            <LoadingButton loading={learnKnowWordLoading} onClick={handleSkip}>
              {"Да"}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleClose}
              loading={learnKnowWordLoading}
            >
              {"Нет"}
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ mb: 2 }}>{"Вы знаете это слово?"}</Box>
        <LoadingButton
          onClick={handleOpen}
          sx={{ ml: 1 }}
          loading={learnKnowWordLoading || learnConfirmLearningWordLoading}
        >
          {"Знаю, пропустить"}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={learnKnowWordLoading || learnConfirmLearningWordLoading}
          onClick={handleContinue}
        >
          {"Не знаю, буду изучать"}
        </LoadingButton>
      </Box>
    </>
  );
}
